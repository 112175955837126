@charset "utf-8";

/* stylelint-disable selector-class-pattern */

.swagger-ui {
  color: var(--foreground);
  font-family: inherit;
}
.swagger-ui .debug-white * {
  outline-color: var(--background);
}
.swagger-ui .opblock-tag {
  border-bottom: 2px solid var(--background);
  color: var(--foreground);
}
.swagger-ui .opblock-tag small {
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .parameter__type {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .opblock .tab-header .tab-item.active h4 span:after {
  background: var(--background);
}
.swagger-ui .opblock .opblock-section-header {
  background: var(--background);
}
.swagger-ui .opblock .opblock-section-header > label {
  color: var(--foreground);
  font-size: 0.8rem;
  font-family: inherit; /* TODO */
}
.swagger-ui .opblock .opblock-section-header h4 {
  color: var(--foreground);
  font-size: 0.85rem;
  font-family: inherit; /* TODO */
}
.swagger-ui .opblock .opblock-summary-method {
  background: var(--background);
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .opblock .opblock-summary-operation-id,
.swagger-ui .opblock .opblock-summary-path,
.swagger-ui .opblock .opblock-summary-path__deprecated {
  color: var(--foreground);
  font-size: 1rem;
}
@media (max-width: 768px) {
  .swagger-ui .opblock .opblock-summary-method,
  .swagger-ui .opblock .opblock-summary-operation-id,
  .swagger-ui .opblock .opblock-summary-path,
  .swagger-ui .opblock .opblock-summary-path__deprecated {
    font-size: 0.8rem;
  }
}
.swagger-ui .opblock .opblock-summary-operation-id {
  font-size: 0.85rem;
}
.swagger-ui .opblock .opblock-summary-description {
  color: var(--foreground);
}
.swagger-ui .tab li {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .opblock-description-wrapper,
.swagger-ui .opblock-external-docs-wrapper,
.swagger-ui .opblock-title_normal {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .opblock-description-wrapper h4,
.swagger-ui .opblock-external-docs-wrapper h4,
.swagger-ui .opblock-title_normal h4 {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .opblock-description-wrapper p,
.swagger-ui .opblock-external-docs-wrapper p,
.swagger-ui .opblock-title_normal p {
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .responses-inner h4,
.swagger-ui .responses-inner h5 {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .response-col_status {
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .response-col_links {
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .opblock-body pre.microlight {
  font-size: 0.8rem;
}
.swagger-ui .download-contents {
  font-size: 0.85rem;
}
.swagger-ui .scheme-container {
  background: var(--xi-gray);
}
.swagger-ui .scheme-container .schemes > label {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .loading-container .loading:after {
  color: var(--foreground);
}
.swagger-ui section h3 {
  color: var(--foreground);
}
.swagger-ui .btn {
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .btn.btn-sm {
  font-size: 0.8rem;
}
.swagger-ui .model-box-control,
.swagger-ui .models-control,
.swagger-ui .opblock-summary-control {
  fill: var(--foreground);
  color: var(--foreground);
}
.swagger-ui .expand-methods svg,
.swagger-ui .expand-operation svg {
  fill: var(--foreground);
}
.swagger-ui select {
  font-size: 0.85rem;
}
.swagger-ui label {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui input[type="email"],
.swagger-ui input[type="file"],
.swagger-ui input[type="password"],
.swagger-ui input[type="search"],
.swagger-ui input[type="text"] {
  color: #000; /* TODO */
}
.swagger-ui textarea {
  background: var(--background);
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui textarea.curl {
  font-size: 0.8rem;
}
.swagger-ui .checkbox p {
  color: var(--foreground);
}
.swagger-ui .dialog-ux .modal-ux {
  background: var(--background);
}
.swagger-ui .dialog-ux .modal-ux-content p {
  color: var(--foreground);
}
.swagger-ui .dialog-ux .modal-ux-content h4 {
  color: var(--foreground);
}
.swagger-ui .dialog-ux .modal-ux-header h3 {
  color: var(--foreground);
}
.swagger-ui .model {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui section.models {
  border-color: var(--background);
}
.swagger-ui section.models.is-open h4 {
  border-bottom-color: var(--background);
}
.swagger-ui section.models h4 {
  font-size: 1rem;
}
.swagger-ui section.models h5 {
  font-size: 1rem;
}
.swagger-ui .model-title {
  font-size: 1rem;
}
.swagger-ui .model-deprecated-warning {
  font-size: 1rem;
}
.swagger-ui .servers > label {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .servers .computed-url code {
  font-size: 1rem;
}
.swagger-ui .servers-title {
  font-size: 0.8rem;
}
.swagger-ui table.headers td {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui table thead tr td,
.swagger-ui table thead tr th {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .parameter__name {
  color: var(--foreground);
  font-size: 1rem;
}
.swagger-ui .parameter__extension,
.swagger-ui .parameter__in {
  font-size: 0.8rem;
}
.swagger-ui .parameter__deprecated {
  font-size: 0.8rem;
}
.swagger-ui .response__extension {
  font-size: 0.8rem;
}
.swagger-ui .topbar .download-url-wrapper .select-label span {
  font-size: 1rem;
}
.swagger-ui .topbar .download-url-wrapper .download-url-button {
  font-size: 1rem;
}
.swagger-ui .info hgroup.main a {
  font-size: 0.8rem;
}
.swagger-ui .info pre {
  font-size: 0.85rem;
}
.swagger-ui .info li,
.swagger-ui .info p,
.swagger-ui .info table {
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .info h1,
.swagger-ui .info h2,
.swagger-ui .info h3,
.swagger-ui .info h4,
.swagger-ui .info h5 {
  color: var(--foreground);
}
.swagger-ui .info a {
  font-size: 0.85rem;
}
.swagger-ui .info .base-url {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .info .title {
  color: var(--foreground);
  font-family: inherit; /* TODO */
  font-size: 3rem;
}
.swagger-ui .auth-container .errors {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .scopes h2 {
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .scopes h2 a {
  font-size: 0.8rem;
}
.swagger-ui .errors-wrapper .errors h4 {
  color: var(--foreground);
  font-size: 0.85rem;
}
.swagger-ui .errors-wrapper hgroup h4 {
  color: var(--foreground);
}
.swagger-ui .markdown code,
.swagger-ui .renderedMarkdown code {
  font-size: 0.85rem;
}
.swagger-ui .json-schema-2020-12-accordion {
  background-color: var(--xi-gray);
}
.swagger-ui .json-schema-2020-12-expand-deep-button {
  font-size: 0.8rem;
  background-color: var(--xi-gray);
}
.swagger-ui .json-schema-2020-12-keyword__name {
  font-size: 0.8rem;
}
.swagger-ui .json-schema-2020-12-keyword__name--primary {
  color: var(--foreground);
}
.swagger-ui .json-schema-2020-12-keyword__value {
  font-size: 0.8rem;
}
.swagger-ui .json-schema-2020-12-keyword__value--primary {
  color: var(--foreground);
}
.swagger-ui .json-schema-2020-12-keyword__value--const,
.swagger-ui .json-schema-2020-12-keyword__value--warning {
  border-color: var(--foreground);
  color: var(--foreground);
}
.swagger-ui .json-schema-2020-12__attribute {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .json-schema-2020-12-keyword--description {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .json-schema-2020-12__title {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .json-schema-2020-12-property .json-schema-2020-12__title {
  color: var(--foreground);
  font-size: 0.8rem;
}
.swagger-ui .json-schema-2020-12__constraint {
  color: var(--foreground);
}
.swagger-ui
  .model-box
  .json-schema-2020-12:not(.json-schema-2020-12--embedded)
  > .json-schema-2020-12-head
  .json-schema-2020-12__title:first-of-type {
  font-size: 1rem;
}
.swagger-ui
  .models
  .json-schema-2020-12:not(.json-schema-2020-12--embedded)
  > .json-schema-2020-12-head
  .json-schema-2020-12__title:first-of-type {
  font-size: 1rem;
}
