@charset "utf-8";

/* The "unauth" layout is a minimal layout for pages to be shown to
   unauthenticated users. See layouts/unauth.html for the markup.
 */
main.unauth-layout {
  h1.logo-large a {
    display: block;
    text-align: center;
    height: 4rem;
    text-indent: -9999px;
    background: url("./images/logo-large.png") center/contain no-repeat;
  }

  .content {
    max-width: 70ch;
    border: var(--regular-line) solid var(--panel-border);
    margin: 2rem auto;
    padding: 2rem;
    background-color: var(--background);

    /* TODO is this the right spot for this? */
    form {
      margin: 0 3rem;

      .prose {
        margin: 0 -3rem;
      }
    }
  }
}

/* The "main" layout covers pages inside the application. See layouts/main.html
   for the markup.
*/
main.main-layout {
  display: grid;
  grid:
    [head-start] "logo    banner " min-content [head-end]
    [body-start] "sidebar content" auto [body-end]
    / 13rem auto;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .logo {
    grid-area: logo;
    margin: 0;
    padding: 0 2rem;
    background-color: var(--xi-red);

    a {
      display: block;
      background: url("./images/logo-large.png") center/contain no-repeat;
      height: 100%;
      max-width: 100%;
      text-indent: -9999px;
    }
  }

  header.banner {
    grid-area: banner;
    display: flex;
    padding: 1rem 2rem;

    & > * {
      margin: 0;
    }

    /* Multiple items may get inserted; the last one before the identification
       block is the fill element. */
    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      width: 2rem; /* because its a flex layout, this is the min size */
    }

    #whoami {
      text-align: right;
      margin-right: 1rem;
    }
  }

  nav.sidebar {
    grid-area: sidebar;
    background-color: var(--background0);
    color: var(--foreground-alt);
    line-height: 1.6;
    border-right: var(--regular-line) solid var(--panel-border);

    display: flex;
    flex-direction: column;
    padding: 1.414rem;
    gap: 1.414rem;

    h3,
    .special {
      color: var(--foreground-alt);
      font-weight: 400;
    }

    h3 {
      font-weight: inherit;
      font-size: inherit;
      margin: 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-weight: 100;
        height: 1.5rem;
        line-height: 1.5rem;
        padding-left: 0.25rem;
      }
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: var(--action-accent);
      }
    }

    hr {
      visibility: hidden;
      flex: 1;
    }
  }

  .content {
    grid-area: content;
    overflow: auto;
    display: flex;
    flex-direction: column;

    & > section {
      background-color: var(--background);
      margin: 2rem;
      padding: 1rem 2rem;
      border: var(--regular-line) solid var(--panel-border);
    }

    table {
      width: 100%;
    }

    & > hr {
      visibility: hidden;
      flex: 1;
      margin: 0;
    }

    & > footer {
      text-align: center;
      color: var(--foreground-alt);
      margin: 2rem;

      p {
        margin: 0;
      }

      a {
        color: var(--foreground-alt);
      }
    }
  }
}
