@charset "utf-8";

/* Base element styles */

html {
  font: 14px / 1.5 var(--sans-serif);
}

body {
  background: var(--background00) url("./images/xi-background.png") left top
    no-repeat;
  color: var(--foreground);
}

/* These are pegged at the same size because we use their position inside the
   layout to communicate the hierarchy. */
h1,
h2 {
  font-size: 2rem;
  font-weight: 300;
}

h3 {
  font-size: 1rem;
  font-weight: inherit;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  margin: 1rem 0;
}

th,
td {
  padding: 0.25rem 1rem;

  &:first-child {
    padding-left: 0.25rem;
  }

  &:last-child {
    padding-right: 0.25rem;
  }
}

th {
  text-align: left;
  white-space: nowrap;
  font-weight: normal;
}

a {
  color: var(--link-accent);
}

a.list-item-section {
  font-weight: 500;
}

li.subscription-left-nav {
  padding-left: 15px !important;
  display: inline-block;
}

[type="email"],
[type="password"],
[type="search"],
select,
textarea {
  background-color: inherit;
  color: var(--foreground);
  border: var(--regular-line) solid transparent;
  border-bottom-color: var(--foreground-alt);
  height: 2em;
}

select option {
  /* Remove the color settings to avoid light-on-light. */
  color: initial;
  background-color: initial;
}

textarea {
  height: 20rem;
  resize: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"],
a.button-like {
  border: none;
  background-color: var(--action-accent);
  color: var(--foreground);
  padding: 0.8rem;
  margin: 0.3rem;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;

  img.htmx-indicator {
    height: 1.1em;
  }

  &:hover {
    background-color: var(--action-hover-accent);
  }

  /* Slight interior shading to indicate a press */
  &:active {
    box-shadow: inset 0 3px 5px rgba(0 0 0 / 12.5%);
  }

  &[disabled] {
    color: var(--background00);

    &:hover {
      color: var(--background00);
    }

    &:active {
      box-shadow: none;
    }
  }
}

/* Semantic utility classes */

.hint {
  font-size: 80%;
}

.disabled-link {
  color: var(--foreground-alt);
}

/* Depressing hacks */

.select-wrapper {
  position: relative;

  select {
    appearance: none;
    width: 100%;
  }

  &::after {
    content: "";
    background: url("chevron-down.svg?inline") no-repeat;
    background-size: 1.1em 1.1em;
    top: 0.45rem;
    right: 0.25rem;
    position: absolute;

    /* Copied from the feather class */
    width: 1.1em;
    height: 1.1em;
    vertical-align: middle;
  }
}

/* Reusable components */

nav.pagination {
  a {
    color: var(--foreground);
  }

  a,
  .disabled-link {
    display: inline-block;
    margin: 0 0.5ch;
  }

  .page-tracker {
    margin: 0 1ch;
  }
}

/* A 'basic' form, i.e. one with a set of fields to fill in and a button to
   submit at the bottom. Used for password entry, settings updates, etc. */
form.basic {
  display: grid;
  grid: auto-flow / repeat(2, max-content) 1fr;
  gap: 1rem 1ch;

  & > * {
    margin: 0;
  }

  label::after {
    content: ":";
  }

  [type="checkbox"] + label::after {
    content: "";
  }

  [type="checkbox"],
  label {
    grid-column: 1;
    text-align: right;
    line-height: 1;
    align-self: center;
  }

  input,
  select,
  .select-wrapper,
  [type="checkbox"] + label {
    text-align: left;
    align-self: center;
    grid-column: 2 / span 2;
  }

  button {
    grid-column: 2;
    justify-self: start;
  }

  /* A list of links to alternatives, if the current form cannot be filled out,
     e.g. a 'forgot password' link. */
  .form-alternatives {
    grid-column: 3;
    text-align: right;
    padding: 0;
    list-style-type: none;
    font-size: 90%;

    a {
      color: var(--foreground-alt);
      text-decoration: none;

      &:hover {
        color: var(--action-accent);
      }
    }
  }

  .flash-messages {
    grid-column: 2 / span 2;
    padding: 0;
  }

  .prose,
  textarea {
    grid-column: 1 / span 3;
  }
}

/* A "command" form is a button that makes a POST request, without any fields
   for the user to fill in. Technically we could do this with a link and HTMX,
   but that breaks the situation when there's no Javascript. */
form.command {
  display: inline-block;

  button {
    background-color: transparent;
    color: var(--foreground);
    padding: 0;
    margin: 0;
    position: relative;

    &:hover {
      background-color: none;
    }
  }
}

form.feedback {
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 5rem;
}

.flash-message {
  list-style-type: none;
  text-indent: -1.4em;

  .feather {
    margin-right: 0.3em;
  }

  &.success {
    color: var(--accept-accent);
  }

  &.admonition {
    color: var(--admonish-accent);
  }

  &.user-error,
  &.server-error {
    color: var(--error-accent);
  }
}

.pagination {
  text-align: center;
}

.no-results {
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
}

form.reports-search,
form.scraps-search,
form.users-search {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;

  .search-options {
    grid-column: 1 / 3;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-rows: repeat(2, max-content);
    grid-auto-flow: column;
    gap: 0 1ch;
    margin-bottom: 1rem;
  }

  label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .extra-actions {
    grid-column: 1 / 3;
    display: flex;
    justify-content: flex-end;

    .bulk-download-button {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  button .htmx-indicator {
    display: none;
    vertical-align: middle;
  }

  &.htmx-request button[value="Search"] {
    .htmx-indicator {
      display: initial;
    }

    .icon {
      display: none;
    }
  }
}

/* The tables */

table.vulns,
table.scraps,
table.expiring-subs {
  color: var(--foreground-alt);

  tbody tr:hover {
    cursor: pointer;
    background-color: var(--panel-border);
    color: var(--foreground-bright);
  }

  th a {
    color: var(--foreground);
    text-decoration: none;

    &:hover {
      color: var(--action-accent);
    }
  }

  td.identifier a {
    font-family: var(--monospace);
    color: inherit;
    text-decoration: none;

    &:hover {
      color: var(--action-accent);
    }
  }

  td.subscription a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: var(--action-accent);
    }
  }

  td a {
    color: inherit;
    text-decoration: none;
  }

  /* Forces these to be as narrow as possible */
  .subscription,
  .identifier,
  .patched,
  .impact,
  .privileges,
  .vector,
  .last_validated_date,
  .date_updated {
    width: 1%;
    white-space: nowrap;
  }

  .patched {
    text-align: center;
  }

  .no-patch {
    color: var(--foreground-alt);
  }

  .has-patch {
    color: var(--accept-accent);
  }
}

table.scraps {
  tbody tr:hover {
    cursor: default;
  }
}

table.events {
  text-align: left;

  th {
    width: 0;
    text-align: left;

    &.event {
      width: auto;
    }
  }

  td {
    white-space: nowrap;
    color: var(--alt-text);
  }

  .ip-address,
  .timestamp {
    width: 1%;
    whitespace: no-wrap;
  }

  .event-description a {
    color: var(--link-accent);
  }
}

table.users {
  text-align: left;

  th {
    width: 0;
    text-align: left;

    &.actions {
      text-align: right;
    }
  }

  & td {
    white-space: nowrap;
    color: var(--alt-text);

    &.actions {
      text-align: right;
      padding-right: 1rem;
    }
  }
}

#report-results {
  tr:nth-child(2n) {
    background-color: var(--background0);

    &:hover {
      background-color: var(--panel-border);
    }
  }
}

#dashboard-view {
  hr {
    border-color: var(--panel-border);
    margin: 2rem 0;
  }

  table.expiring-subs {
    color: var(--foreground);
  }

  table.vulns {
    color: var(--foreground);
  }

  table.scraps {
    color: var(--foreground);
  }

  th {
    font-weight: bold;
  }

  h3 {
    color: var(--foreground-alt);
  }
}

input.global-search {
  width: 300px;
}

button.global-search {
  margin-right: 250px;
}

.alert-info {
  background-color: #eed202 !important;
  color: black;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 4px;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
}

.alert-info h3 {
  margin-top: 0;
  color: inherit;
}

.alert-info table,
.alert-info table th,
.alert-info table td {
  background-color: transparent;
  color: inherit;
}

#expiring-subs-floating {
  margin-bottom: 0.5rem;
}

#dashboard-view {
  margin-top: 0.5rem;
}

#subscription-tag {
  background-color: var(--foreground-alt) !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.2em 0.5em;
  margin-left: 1em;
  cursor: help;
}

#expiring-soon-banner {
  color: black;
  background-color: #eed202;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
  font-size: 0.5em;
}

#subscription-expiring-soon {
  color: black;
  background-color: #eed202;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#subscription-active {
  color: var(--foreground-alt);
  padding: 5px;
}
