@charset "utf-8";

/* Fade-in animation for help popovers */
@keyframes help {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#report {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 1rem;
  gap: 1rem;

  .indicator {
    font-size: 6rem;
  }

  & > section {
    border: var(--regular-line) solid var(--panel-border);
  }

  h3 {
    margin: 1rem;
    color: var(--foreground-alt);
  }

  p,
  table,
  ul {
    margin: 1em;
  }

  /* For tiles that have help text, we wrap the header in a details element
     with an appropriate summary. This allows us to present the help text.
   */
  details.help {
    /* We give it an explicit height so that when it expands, the contents
       overflows, making it function as a popover. */
    height: 1.5rem; /* text height * line height */
    margin: 1rem;

    h3 {
      margin: 0;
    }

    summary {
      display: block;
      color: var(--foreground-alt);

      .icon {
        float: right;
        margin: 0 0 0 -1rem; /* makes the effective width 0 */
        cursor: pointer;
        transition:
          0.25s transform ease,
          0.25s color ease;
      }
    }

    aside {
      /* The relative positioning ensures that it stays on top. */
      position: relative;
      z-index: 1;
      margin: 0 0 0 auto;
      max-width: 70ch;
      contain: layout;
      background-color: var(--background0);
      text-align: left;
      font-size: 0.9rem;
      box-shadow: 0 0.5rem 1rem -0.5rem black;

      dl {
        display: grid;
        grid-template-columns: min-content 1fr;
        margin: 1em;
        gap: 1em 0.5ex;

        dt,
        dd {
          margin: 0;
        }

        dt {
          font-style: italic;
          text-align: right;
          white-space: nowrap;

          &::after {
            padding-left: 0.5ex;
            content: "—";
          }
        }
      }
    }

    &[open] {
      summary .icon {
        color: var(--foreground);
        transform: rotate(-45deg);
      }

      summary ~ * {
        animation: help 0.25s ease;
      }
    }
  }

  /* Styling for the report timeline. */
  #report-timeline {
    border-width: 0;
    grid-column-end: span 3;

    /* The table styles are a very bare-bones fallback for people with
       JavaScript disabled; if its enabled, we use D3 to draw a nice SVG chart.
     */
    table {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: 1fr 1fr;

      thead,
      tbody,
      tr {
        display: contents;
      }

      td.event-name {
        border-left: var(--regular-line) solid var(--foreground);
      }

      td.event-date {
        color: var(--foreground-alt);
      }
    }

    svg {
      width: 100%;
      height: 150px;

      #legendBgGradient {
        .start {
          stop-color: var(--background);
          stop-opacity: 0;
        }

        .end {
          stop-color: var(--background);
          stop-opacity: 1;
        }
      }

      .timeline-base {
        stroke: var(--panel-border);
        stroke-width: 8;
        stroke-dasharray: 8;
      }

      .timeline-filled {
        stroke: var(--xi-red);
        stroke-width: 8;
      }

      .event-legend {
        overflow: visible;

        line {
          stroke: var(--foreground);
        }

        circle {
          stroke: var(--foreground);
        }

        &.future circle {
          fill: none;
          &:hover,
          &.fakehover {
            fill: var(--xi-gray);
          }
        }

        &.past circle {
          fill: var(--xi-gray);
          &:hover,
          &.fakehover {
            fill: var(--foreground-alt);
          }
        }

        /* Must come after past, as the present will have that class too */
        &.present circle {
          fill: var(--xi-red);
          &:hover,
          &.fakehover {
            fill: var(--action-hover-accent);
          }
        }
      }

      .event-background {
        fill: url("#legendBgGradient");
      }

      .event-name {
        fill: var(--foreground);
      }

      .event-date {
        fill: var(--foreground-alt);
      }
    }
  }

  /* Styling for the type indicator */
  #report-latest-validation {
    text-align: center;

    & > div {
      display: inline-block;
      width: 45%;
    }

    & > #affected_platform {
      width: auto;
    }

    h1 {
      font-size: 1.2em;
      margin-bottom: 0;
    }
  }

  /* Styling for the available content */
  #report-available-content {
    text-align: center;

    ul {
      padding: 0;
      list-style-type: none;
      text-transform: uppercase;
      display: inline-block;
      text-align: left;

      .icon {
        margin-left: -2em;
        width: 2em;
      }
    }
  }

  /* CVSS score styling */
  #report-cvss-score {
    text-align: center;

    p.indicator {
      margin: 0;
    }

    /* Placeholder styling for if we can't draw the pretty d3 gauge */
    meter {
      display: inline-block;
      vertical-align: middle;
      transform: rotate(-90deg);
      line-height: 1;
      width: 1em;
    }

    svg.gauge {
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.5rem;
      height: 1.5em;

      .legend,
      .marker {
        fill: var(--foreground);
      }

      .track {
        fill: var(--panel-border);
      }
    }

    p.breakdown {
      color: var(--foreground-alt);
      font-size: 0.9rem;
      a {
        color: inherit;
        font-style: italic;
      }
    }
  }

  /* Overview styling */
  #report-overview {
    grid-row-end: span 2;
    grid-column-end: span 2;
  }

  #report-public-ids {
    ul {
      list-style-type: none;
      padding: 0;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: var(--action-accent);
      }
    }
  }

  #report-downloads {
    border-width: 0;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      a.button-like {
        display: block;
        text-align: center;
      }
    }
  }

  #report-affected-versions {
    grid-column-end: span 3;

    /* We wrap the table in a div in order to get the horizontal scrolling
       behavior we need: specifically, we need the table to have 'table' layout
       so that the columns are auto-sized, but we need the scrollbox to have
       'block' layout so that we get the correct clipping behavior. */
    div.table-scroller {
      overflow-x: auto;
      margin: 0 1rem;

      table {
        margin: 1rem 0;
      }
    }

    th {
      font-size: 1rem;
      white-space: nowrap;
    }

    th,
    td {
      /* Bigger spacing to compensate for the visible gridlines. */
      padding: 0.5em 0.5em;
      text-align: center;

      /* This prevents the label from taking up all the horizontal space */
      &:first-child {
        width: 30%;
      }
    }

    thead th {
      &:has(> div) {
        height: 10rem;
      }

      & > div {
        margin: 0 auto;
        width: 2rem;
        transform: translate(0, 3rem) rotate(-45deg);
      }
    }

    tbody {
      th {
        border-right: var(--regular-line) solid var(--panel-border);
      }

      tr {
        border-top: var(--regular-line) solid var(--panel-border);
      }
    }

    .icon {
      &.unaffected {
        color: var(--error-accent);
      }

      &.affected {
        color: var(--accept-accent);
      }
    }
  }
}
