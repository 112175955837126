@charset "utf-8";

/* Legal styles
   These styles apply to legal wording, such as the EULA. Be careful about
   changing them; styling (such as underlining) can have specific meaning. */
.legal {
  text-align: justify;
  hyphens: auto;

  u {
    text-decoration: underline;
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    padding-inline-start: 1ch;

    li {
      list-style: inherit;
      margin: 1em 0;

      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
      }
    }
  }

  dt {
    display: inline-block;
    text-decoration: underline;
    margin-top: 1em;
  }

  dd {
    display: inline;
    margin: 0;

    &::after {
      content: "\A";
      white-space: pre;
    }
  }

  h3,
  h4 {
    display: inline;
    font-size: 1em;
    font-weight: normal;
    text-decoration: underline;
  }
}
