@charset "utf-8";

:root {
  /* Brand colors */
  --xi-red: oklab(60.8% 0.171 0.082);
  --xi-gray: oklab(36.8% 0 0);

  /* Brand-derived colors */
  --xi-green: oklab(80% -0.154 0.064); /* split complement green */
  --xi-blue: oklab(80% -0.043 -0.135); /* split complement blue */
  --xi-dark-red: oklab(54% 0.171 0.082);

  /*
   * # Backgrounds and foregrounds
   *
   * The vault UI can be thought of as a set of 3 background layers: the page
   * background, the sidebar background, and the content area background.
   */
  --background00: var(--xi-gray); /* page background */
  --background0: oklab(35% 0 0); /* secondary content (sidebar) background */
  --background: oklab(32.9% 0 0); /* content background */
  --panel-border: oklab(44% 0 0); /* borders */
  --foreground-alt: oklab(72% 0 -0.01); /* secondary/hint text */
  --foreground: #f6f6f4; /* primary content */
  --foreground-bright: white;

  /* Semantic colors */
  --accept-accent: var(--xi-green);
  --link-accent: var(--xi-blue);
  --admonish-accent: var(--xi-blue);
  --error-accent: var(--xi-red);
  --action-accent: var(--xi-red);
  --action-hover-accent: var(--xi-dark-red);

  /* Measurements */
  --regular-line: 0.1rem;

  /* Fonts */
  --sans-serif: "Source Sans 3", sans-serif;
  --monospace: "Source Code Pro", monospace;
}
